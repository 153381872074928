import React from 'react';
import config from "../config/config";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>
          <div className='footer_panel_left'>
            <p>Smart-contract address: </p>
            <a href={config.matricurl+"address/"+config.polytrustcontract} target="_blank">{config.polytrustcontract}</a>         
            <p>Stake-contract address: </p>
            <a href={config.matricurl+"address/"+config.stakecontract} target="_blank">{config.stakecontract}</a>         
            <p>Copy right ©{new Date().getFullYear()} PolyTrust, All rights Reserved</p>
            {/* <a href={require("../assets/pdf/Termsandconditions.pdf")} target="_blank">Terms and Conditions</a> */}
          </div>
         <div className='footer_panel_right'>
          
            <p>Social media</p>
            <ul className='footer_social_links'>
              {/*<li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
               <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
              <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>*/}
              <li><a href="https://chat.whatsapp.com/LXWEZkfej2U4qM0rJQmFdm" target="_blank"><i class="fab fa-whatsapp"></i></a></li> 
              <li><a href="https://t.me/polytrust" target="_blank"><i class="fab fa-telegram-plane"></i></a></li>
            </ul> 
            
          </div>
        </div>
      </div>
    </footer>
  );  
}