import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import ScrollAnimation from "react-animate-on-scroll";
import Web3 from "web3";
import moment from "moment";
import syncEach from 'sync-each';
import abiDecoder from 'abi-decoder';
import axios from "axios";
import { getCurAddr, get_lastesttrx } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import config from "../config/config";
import { setWallet } from "../reducers/Actions";
import { type WalletClient, useWalletClient } from 'wagmi'
import { BrowserProvider, JsonRpcSigner } from 'ethers'
import { useSelector, useDispatch } from "react-redux";
import Aboveinfo from "../components/aboveinfo.js";
import { Scrollbars } from "react-custom-scrollbars";
import polygonapi from "../ABI/polytrustABI";
import timestampapi from "../ABI/timestampapi";
abiDecoder.addABI(polygonapi);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [data, setdata] = useState({});
  const [latesttrx, setlatesttrx] = useState([]);
  const [providerValue, setproviderValue] = useState({ signer: {}, transport: {} });
  const dispatch = useDispatch()
  let chainid = config.networkVersion;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    getdetails();
    // getrecentactivity();
  }, [walletClient]);

  async function getrecentactivity() {
    try {
      let respData = await axios({
        'method': 'get',
        'url': `https://api.polygonscan.com/api?module=account&action=txlist&address=0x22E34f898Fe51b2cC1f627044835b11332E9eD9b&page=1&offset=10&sort=desc&apikey=63USVUPDTS245DR5KGM3MN8SE4C9BKRUVJ`
      });
      const web3_bsc = new Web3('https://matic-mainnet.chainstacklabs.com/');//mainnet
      const polygonpayContract = new web3_bsc.eth.Contract(polygonapi, config.polytrustcontract);
      let arrrec = [];
      if (respData.data && respData.data.result && (respData.data.result).length > 0) {
        syncEach((respData.data.result), async function (items, next) {
          let trans_hash = await web3_bsc.eth.getTransaction(items.hash);
          if (trans_hash && trans_hash.input) {
            let trans_detail = abiDecoder.decodeMethod(trans_hash.input);
            let userdet = await polygonpayContract.methods.users(items.from).call();
            let date = moment.unix(items.timeStamp).toDate();
            var now = moment(new Date()); //todays date
            var end = moment(date); // another date
            var duration = now.diff(end, "minutes");
            var duration1 = now.diff(end, "hours");
            if (trans_detail && trans_detail.name && trans_detail.name == "claimRewards") {
              let datas = {
                "address": web3_bsc.utils.toChecksumAddress(items.from),
                "userid": userdet.id,
                "event": trans_detail.name,
                "value": 0,
                "trx": items.hash,
                "min": duration,
                "hours": duration1

              }
              arrrec.push(datas);
              next();
            } else if (trans_detail && trans_detail.name && trans_detail.name == "regUser") {
              let datas = {
                "address": web3_bsc.utils.toChecksumAddress(items.from),
                "userid": userdet.id,
                "event": trans_detail.name,
                "value": 0,
                "trx": items.hash,
                "min": duration,
                "hours": duration1
              }
              arrrec.push(datas);
              next();
            } else if (trans_detail && trans_detail.name && trans_detail.name == "upgradeNextLevel") {
              let datas = {
                "address": web3_bsc.utils.toChecksumAddress(items.from),
                "userid": userdet.id,
                "event": trans_detail.name,
                "value": 0,
                "trx": items.hash,
                "min": duration,
                "hours": duration1
              }
              arrrec.push(datas);
              next();
            } else {
              next();
            }
          } else {
            next();
          }
        }, function (err, transformedItems) {
          setlatesttrx(arrrec);
        })
      }

    } catch (err) {
      console.log(err, "=======ininierr")
    }
  }

  async function checking() {
    try {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != ""
      ) {
        let web3 = new Web3(
          datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
        var polygonContract = new web3.eth.Contract(
          polygonapi,
          config.polytrustcontract
        );
        let roi_start_time = await polygonContract.methods
          .roiStartTime("0xc6740aab635e182766e9e6c5367c820ccc13c7c6")
          .call();
        let roi_end_time = await polygonContract.methods
          .roiEndTime("0xc6740aab635e182766e9e6c5367c820ccc13c7c6")
          .call();
        let launchtime = await polygonContract.methods.roiLaunchTime().call();
        let timestampcontract = new web3.eth.Contract(
          timestampapi,
          config.timestampcontract
        );
        let nowtimestamp = await timestampcontract.methods
          .getCurrentTimestamp()
          .call();
        let sec_hund = parseInt(launchtime) + 100 * 24 * 60 * 60;
        var startdate =
          roi_start_time && roi_start_time != 0 ? roi_start_time : launchtime;
        var enddate =
          roi_end_time && roi_end_time != 0 ? roi_end_time : sec_hund;
        var duration = parseInt(nowtimestamp) - parseInt(startdate);
        var remaining_days = 100 - parseInt(duration / 86400);
        remaining_days = remaining_days > 0 ? remaining_days : "--";
        console.log(remaining_days, "=========remaining_daysremaining_days");
      } else {
      }
    } catch (err) {
      console.log(err, "====rrreee");
    }
  }

  async function getdetails() {
    // let record = await get_lastesttrx();
    // if (record && record.data && record.data.data) {
    //setlatesttrx(record.data.data);
    // }
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
      if (datas.userdetails && datas.userdetails.isExist) {
        //window.location.href="/dashboard"
      } else {
      }
    }
  }

  async function movingnext() {
    if (
      data &&
      data.address &&
      data.address != undefined &&
      data.address != null &&
      data.address != "" &&
      localStorage.getItem("account")
    ) {
      if (data.userdetails && data.userdetails.isExist) {
        window.location.href = "/dashboard";
      } else {
        window.location.href = "/join-now";
      }
    } else {
      window.location.href = "/join-now";
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="page_header">
        <div className="ct-particle-animate">
          <div id="ct_particle_animate-a461fd71" className="shape-animate1">
            <img
              width="114"
              height="115"
              src={require("../assets/images/bg_token_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd72" className="shape-animate2">
            <img
              width="115"
              height="114"
              src={require("../assets/images/bg_token_02.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd73" className="shape-animate3">
            <img
              width="93"
              height="92"
              src={require("../assets/images/bg_token_03.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd74" className="shape-animate4">
            <img
              width="93"
              height="92"
              src={require("../assets/images/bg_token_04.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd75" className="shape-animate5">
            <img
              width="114"
              height="115"
              src={require("../assets/images/bg_token_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          {/* <div id="ct_particle_animate-a461fd77" className="shape-left-right">
            <img
              width="104"
              height="102"
              src={require("../assets/images/bg_token_02.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd76" className="shape-right-left">
            <img
              width="80"
              height="79"
              src={require("../assets/images/bg_token_03.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div> */}
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <ScrollAnimation animateIn="slideInUp">
                <h1>Poly Trust - Decentralized Binary Affiliate System</h1>
              </ScrollAnimation>
              <ScrollAnimation animateIn="slideInUp">
                <h5>
                  A decentralized networking platform based on smart contracts,
                  which <span>brings people together</span> from all over the
                  world and <span>opens up endless possibilities</span> new
                  economic financial Freedom.
                </h5>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* Feature Section */}
        <section className="features_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6 m-auto">
                <ScrollAnimation animateIn="zoomIn">
                  <h2 className="h2tag text-center">How PolyTrust works</h2>
                </ScrollAnimation>
              </div>
            </div>

            <div className="features_sectionbg">
              <div className="row">
                <div className="col-lg-4">
                  <ScrollAnimation animateIn="flipInX">
                    <div className="primary_box features_box features_box_01">
                      <div className="imgbox">
                        <img
                          src={require("../assets/images/f1.png")}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <h4>Smart Contract on Blockchain</h4>
                        <p className="inner_para">
                          Distributed on Polygon blockchain using a smart
                          contract, moreover it can't be hacked, edited or
                          cancelled. PolyTrust.io will exist for ever until
                          blockchain does.
                        </p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-4">
                  <ScrollAnimation animateIn="flipInX">
                    <div className="primary_box features_box features_box_02">
                      <div className="imgbox">
                        <img
                          src={require("../assets/images/f2.png")}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <h4>Web3 100% Automated System</h4>
                        <p className="inner_para">
                          PolyTrust System is created with a vision to make
                          economic growth to the each and every users in
                          polytrust.io works with web3 decentralised technology.
                        </p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-4">
                  <ScrollAnimation animateIn="flipInX">
                    <div className="primary_box features_box features_box_03">
                      <div className="imgbox">
                        <img
                          src={require("../assets/images/f3.png")}
                          className="img-fluid"
                          alt="img"
                        />
                      </div>
                      <div>
                        <h4>Are the funds safe here</h4>
                        <p className="inner_para">
                          Yes, your funds are very safe and secured. The
                          complete system included the automated upgrades
                          guaranteeing from POLYGON Blockchain. The possibility
                          to be lose your funds is zero.
                        </p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Platform recent activity */}
       {/* <section className="platform_activity">
          <div className="container">
            <div className="head">
              <ScrollAnimation animateIn="zoomIn">
                <h2 className="h2tag">Platform recent activity</h2>
              </ScrollAnimation>
            </div>

            <ScrollAnimation animateIn="zoomIn">
              <div className="activity_box">
                <Scrollbars style={{ width: "100%", height: 500 }}>
                  <div className="activity_scroll">
                    {latesttrx &&
                      latesttrx.length > 0 &&
                      latesttrx.map((item) => {

                        return (
                          <div className="activity_box_flx">
                            <div>
                              <div className="flxbox">
                                <div>
                                  <img
                                    src={require("../assets/images/polygon_logo.png")}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                </div>
                                {item.event == "regUser" ? (
                                  <div>
                                    <img
                                      src={require("../assets/images/user_prf.png")}
                                      className="img-fluid"
                                      alt="img"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={require("../assets/images/wallet.png")}
                                      className="img-fluid"
                                      alt="img"
                                    />
                                  </div>
                                )}
                                {item.event == "regUser" && (
                                  <div>
                                    <p className="violet">New user joined</p>
                                  </div>
                                )}
                                {item.event == "upgradeNextLevel" && (
                                  <div>
                                    <p className="violet">Upgrade Next Level</p>
                                  </div>
                                )}
                                <div>
                                  <div className="btn">ID {item.userid}</div>
                                </div>
                                {item.event == "claimRewards" && (
                                  <div>
                                    <p className="green">+ Matic </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="rightbtn">
                              <p>
                                <span>
                                  {" "}
                                  <a
                                    href={config.matricurl + "tx/" + item.trx}
                                    target="_blank"
                                  >
                                    <img
                                      src={require("../assets/images/arrow.png")}
                                      className="img-fluid me-2"
                                      alt="img"
                                    />
                                  </a>
                                </span>
                                <span>
                                  {" "}
                                  {item.hours > 0 ? item.hours : item.min}{" "}
                                  {item.hours > 0 ? " Hours" : " Min"}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Scrollbars>

              </div>
            </ScrollAnimation>
          </div>
        </section>*/}

        {/* How It Works Section */}
        <section className="how_works_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ScrollAnimation animateIn="zoomIn">
                  <h2 className="h2tag text-center">How it Works</h2>
                  <div className="text-center">
                    <img
                      src={require("../assets/images/how_work.png")}
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="box">
                        <h3>Direct Referral Income</h3>
                        <p>Whenever you directly referred new id’s you will automatically get direct referral income which is of 20% from each ID. You can refer any number of persons with your referral link which help your team grow and you to earn 20% income on referrals.</p>
                      </div>
                    </div>
                    {/*<div class="col-md-4">
                      <div class="box rightbox">
                        <h3>LEVEL 2 Generation Income</h3>
                        <p>Get 3.6 MATIC from Each ID from your Level 2 , 4 Members . Total 14.40 MATIC as Level 2 Generation Income.
                        </p>
                      </div>
                    </div>*/}
                  </div>
                  <div className="text-center">
                    <button
                      className="btn butn mt-5"
                      type="button"
                      onClick={() => movingnext()}
                    >
                      join now 30 Matic fee{" "}
                    </button>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* Plan Income Section */}
        <section className="income_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <ScrollAnimation animateIn="zoomIn">
                  <div className="head">
                    <h2 className="h2tag">Income Plan</h2>
                    {/* <p className='inner_para text-center mb-0'>Moving Forward together.</p> */}
                    {/* <p className="inner_para text-center mb-0">
                      Join the Poly Trust today and become a part of a
                      revolutionary community that is driven by the common goal
                      of securing a brighter future.
                    </p> */}
                    {/* <p className="inner_para text-center mb-0">
                      Together, we will work hand-in-hand to redefine earning
                      and bring innovation to the DeFi space.
                    </p> */}
                    {/* <p className='inner_para text-center contract'> Verified Contract:<span>0x2a4e97a5C3bF4Ef1BAe13dc1bb6062C07Bb91dfA</span> </p> */}
                  </div>
                  <ul
                    className="nav nav-pills mb-3 justify-content-center plan_tab"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="plan_a-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#plan_a"
                        type="button"
                        role="tab"
                        aria-controls="plan_a"
                        aria-selected="true"
                      >
                        Binary Booster Income
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="plan_b-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#plan_b"
                        type="button"
                        role="tab"
                        aria-controls="plan_b"
                        aria-selected="false"
                      >
                        ROI Income
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="plan_a"
                      role="tabpanel"
                      aria-labelledby="plan_a-tab"
                      tabIndex={0}
                    >
                      <div className="plan_income_table_div">
                        <div className="table-responsive">
                          <table className="table plan_income_table">
                            <thead>
                              <tr>
                                <th>Level</th>
                                <th>Your Level Fee</th>
                                <th>Downline</th>
                                <th>Your Downline Level</th>
                                <th>Income Matic</th>
                                <th>Net Income</th>
                                {/*<th>Team Size</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>10</td>
                                <td>2</td>
                                <td>1</td>
                                <td>20</td>
                                <td>0</td>
                                {/*<td>2</td>*/}
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>20</td>
                                <td>4</td>
                                <td>2</td>
                                <td>80</td>
                                <td>40</td>
                               {/*<td>16</td>*/}
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>40</td>
                                <td>8</td>
                                <td>3</td>
                                <td>320</td>
                                <td>160</td>
                                {/*<td>64</td>*/}
                              </tr>
                              <tr>
                                <td>4</td>
                                <td>160</td>
                                <td>16</td>
                                <td>4</td>
                                <td>2560</td>
                                <td>1280</td>
                                {/*<td>256</td>*/}
                              </tr>
                              <tr>
                                <td>5</td>
                                <td>1280</td>
                                <td>32 </td>
                                <td>5</td>
                                <td>40960</td>
                                <td>20480</td>
                                {/*<td>1024</td>*/}
                              </tr>
                              <tr>
                                <td>6</td>
                                <td>20480</td>
                                <td>2 </td>
                                <td>6</td>
                                <td>40960</td>
                                <td>20480</td>
                                {/*<td>2</td>*/}
                              </tr>
                              <tr>
                                <td>7</td>
                                <td>20480</td>
                                <td>4 </td>
                                <td>7</td>
                                <td>81920</td>
                                <td>40960</td>
                                {/*<td>16</td>*/}
                              </tr>
                              <tr>
                                <td>8</td>
                                <td>40960</td>
                                <td>8 </td>
                                <td>8</td>
                                <td>3,27,680</td>
                                <td>1,63,840</td>
                                {/*<td>64</td>*/}
                              </tr>
                              <tr>
                                <td>9</td>
                                <td>1,63,840</td>
                                <td>16 </td>
                                <td>9</td>
                                <td>26,21,440</td>
                                <td>13,10,720</td>
                                {/*<td>256</td>*/}
                              </tr>
                              <tr>
                                <td>10</td>
                                <td>13,10,720</td>
                                <td>32 </td>
                                <td>10</td>
                                <td>4,19,43,040</td>
                                <td>4,19,43,040</td>
                                {/*<td>1024</td>*/}
                              </tr>
                              {/* <tr className="footer_row">
                                <td colSpan={8}>
                                  <h4 className="text-center">
                                    Total Matic : 6923730.00
                                  </h4>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="plan_b"
                      role="tabpanel"
                      aria-labelledby="plan_b-tab"
                      tabIndex={0}
                    >
                      <div className="plan_income_table_div">
                        <div className="table-responsive">
                          <table className="table plan_income_table level_income">
                            <thead>
                              <tr>
                                <th>Level</th>
                                <th>Investment</th>
                                <th>Daily ROI</th>
                                <th>Daily Matic</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>10</td>
                                <td>1.5%</td>
                                <td>0.15 Matic</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>20</td>
                                <td>1.5%</td>
                                <td>0.30 Matic</td>
                              </tr>
                              {/* <tr>
                                <td>3</td>
                                <td>40</td>
                                <td>2%</td>
                                <td>0.80 Matic</td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td>160</td>
                                <td>2%</td>
                                <td>3.2 Matic</td>
                              </tr>
                              <tr>
                                <td>5</td>
                                <td>1280</td>
                                <td>2%</td>
                                <td>25.6 Matic</td>
                              </tr> */}





                              {/*<tr>
                                  <td colSpan={3}><span>&nbsp;</span></td>
                                </tr>*/}
                              {/*<tr>
                                  <td colSpan={3}><h4 className='text-center'>Total MATIC: 319021.20</h4></td>
                                </tr>*/}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* faq Section */}
        <section className="faq_section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-8 m-auto">
                <ScrollAnimation animateIn="zoomIn">
                  <h2 className="h2tag text-center">FAQ</h2>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="accordion" id="faq_accodian">
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is PolyTrust.io?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>PolyTrust.io is an online crypto-decentralized ecosystem provides a platform where people are able to exchange wealth.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How do I participate?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>Join PolyTrust.io Community by Transferring 30 MATIC To PolyTrust.io Contract Address and Become a Life Time Member of the Polytrust.io Community.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          I don't have a DAPP wallet. How can I create?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>Just download Trust wallet or Metamask wallet and add network Polygon.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                        >
                          I don't have MATIC Where can I get it?
                        </button>
                      </h2>
                      <div
                        id="collapse4"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingfour"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>You can purchase from binance.com are any centralized crypto exchanges.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsefive"
                          aria-expanded="false"
                          aria-controls="collapsefive"
                        >
                          How to change the address of the MATIC wallet?
                        </button>
                      </h2>
                      <div
                        id="collapsefive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingfive"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>You cannot change it, it's totally decentralized system and does not have an administrator.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsesix"
                          aria-expanded="false"
                          aria-controls="collapsesix"
                        >
                          How much can I earn with an investment of 30 MATIC?
                        </button>
                      </h2>
                      <div
                        id="collapsesix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingsix"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>It depends upon you and your team effort.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseseven"
                          aria-expanded="false"
                          aria-controls="collapseseven"
                        >
                          How long does it take to My earnings Updates in My Dashboard?
                        </button>
                      </h2>
                      <div
                        id="collapseseven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingseven"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>All the payment systems connected with your upline and downline contract addresses on the blockchain, System will automatically updates the rewards instantly.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingeight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseeight"
                          aria-expanded="false"
                          aria-controls="collapseeight"
                        >
                          How long does it take to activate the ID?
                        </button>
                      </h2>
                      <div
                        id="collapseeight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingeight"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>It's on blockchain smart contract activation that will happen in seconds.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingnine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsenine"
                          aria-expanded="false"
                          aria-controls="collapsenine"
                        >
                          What is the minimum amount for withdrawal?
                        </button>
                      </h2>
                      <div
                        id="collapsenine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingnine"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>There is No Minimum Withdrawal. You make any amount withdrawal available in your balance.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingten">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseten"
                          aria-expanded="false"
                          aria-controls="collapseten"
                        >
                          Poly Trust account has validity?
                        </button>
                      </h2>
                      <div
                        id="collapseten"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingten"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>No, It's a lifetime.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="flipInX">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingeleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseeleven"
                          aria-expanded="false"
                          aria-controls="collapseeleven"
                        >
                          Does it allow having multiple accounts?
                        </button>
                      </h2>
                      <div
                        id="collapseeleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingeleven"
                        data-bs-parent="#faq_accodian"
                      >
                        <div className="accordion-body">
                          <p>It will only create one wallet address and one account, Yes, you can have multiple wallet addresses and can create multiple accounts.</p>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
