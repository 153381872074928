// let NODE_ENV = 'production';
let NODE_ENV = 'demo';



let Front_URL = '';
let Base_URL = '';
let rpcURL = '';
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = '';
let matricurl = '';
let adminAddress = ""
let polytrustcontract = ""
let stakecontract = ""
let chainId = ""
let Server_URL = "";
let livechainid = "0x137";
let rootaddress = "";
let timestampcontract = "";
let projectId = "cb89ebb21cdccb2e1b591e189e27706a";


let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

if (NODE_ENV === "production") {
    Front_URL = 'https://www.polytrust.io/';
    Server_URL = 'https://api.polytrust.io/';
    networkVersion = 137;
    rpcURL = 'https://polygon-rpc.com';
    matricurl = "https://polygonscan.com/";
    adminAddress = "0xf24362c2be0e2d397d0fb7d5fb4269a2dbd0b8b2";
    rootaddress = "0xF24362C2be0E2d397d0fb7D5fb4269A2DBd0b8B2";
    timestampcontract = "0xAbB58f904f4DB60d9EdDeaA642a38914e16AfEC8";
    // polytrustcontract = "0x21d8c29C908355989ef392676Af3DF29A71f9bA9";//"0x1e44EF426FB81CA836B17C739E8eF27b185569Ea";
    polytrustcontract = "0xB9A1130C5B08d677975223C2040e80d30801F3B1",
    stakecontract = "0xC2d771638ed82710741bCefF32BD75631A9cE5d2";//"0x99b9024622355aD1f6805Fc09779032af1c119D2";
    projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
    chainId = 137;
}
else if (NODE_ENV === "demo") {
    // Front_URL = 'http://localhost:3000/';
     //Server_URL = 'http://localhost:3076/'
    Front_URL = 'https://polytrust-frontend.pages.dev/';
    Server_URL = 'https://polytrustapi.wearedev.team/';
    networkVersion = 97;
    rpcURL = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    matricurl = "https://testnet.bscscan.com/";
    adminAddress = "0x373F886904048975034488320E37d171E092C135";
    rootaddress = "0x373F886904048975034488320E37d171E092C135";
    timestampcontract = "0xAbB58f904f4DB60d9EdDeaA642a38914e16AfEC8";
    polytrustcontract = "0x500c62a5714e937cdc094fc1ad65a7ed092559e4";
    stakecontract = "0x66A0425f0aDa758f77356F685DC8a8Fee34cEA43";
    projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
    chainId = 97;
}
else {
    Front_URL = 'http://localhost:3000/';
    Server_URL = 'http://localhost:3076/';
    networkVersion = 137;
    rpcURL = 'https://polygon-rpc.com';
    matricurl = "https://mumbai.polygonscan.com/";
    adminAddress = "0x373F886904048975034488320E37d171E092C135";
    rootaddress = "0xF24362C2be0E2d397d0fb7D5fb4269A2DBd0b8B2";
    timestampcontract = "0xAbB58f904f4DB60d9EdDeaA642a38914e16AfEC8";
    polytrustcontract = "0xB57de6A52DBf31805D5b251926a1f8C69E71A0F7";
    projectId = "cb89ebb21cdccb2e1b591e189e27706a";//wallet connect
    chainId = 97;
}

let key = {
    Front_URL: Front_URL,
    Server_URL: Server_URL,
    decimalvalues: decimalvalues,
    toFixed: toFixed,
    rpcURL: rpcURL,
    networkVersion: networkVersion,
    toasterOption: toasterOption,
    adminAddress: adminAddress,
    rootaddress: rootaddress,
    polytrustcontract: polytrustcontract,
    stakecontract: stakecontract,
    timestampcontract: timestampcontract,
    matricurl: matricurl,
    chainId: chainId,
    livechainid: livechainid,
    projectId: projectId
};

export default key;
